import { useEffect } from "react"

const Blank = () =>{
    useEffect(()=>{

    },[])
    return(
        <div></div>
    )
}
export default Blank;