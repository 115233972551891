// ModernAtsResumeTemplate.js
import React from 'react';

const Preview113 = () => {
  return (
    <div>
      <h1>Modern ATS Resume Template</h1>
      <p>COMING SOON</p>
    </div>
  );
};

export default Preview113;
