// TechAtsResumeTemplate.js
import React from 'react';

const Preview115 = () => {
  return (
    <div>
      <h1>Tech ATS Resume Template</h1>
      <p>COMING SOON</p>
    </div>
  );
};

export default Preview115;
