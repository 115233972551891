// MinimalistAtsResumeTemplate.js
import React from 'react';

const Preview112 = () => {
  return (
    <div>
      <h1>Minimalist ATS Resume Template</h1>
      <p>COMING SOON</p>
    </div>
  );
};

export default Preview112;
