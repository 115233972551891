// TraditionalAtsResumeTemplate.js
import React from 'react';

const Preview116 = () => {
  return (
    <div>
      <h1>Traditional ATS Resume Template</h1>
      <p>COMING SOON</p>
    </div>
  );
};

export default Preview116;
