import {
    FaMusic,
    FaCode,
    FaBicycle,
    FaBook,
    FaPaintBrush,
    FaCamera,
    FaGamepad,
    FaFootballBall,
    FaRunning,
    FaHiking,
    FaSwimmer,
    FaFish,
    FaTree,
    FaFilm,
    FaPlane,
    FaChess,
    FaMountain,
    FaCar,
    FaCoffee,
    FaDumbbell,
    FaWineGlass,
    FaApple,
    FaPenNib,
    FaGuitar,
    FaHammer,
    FaHeart,
    FaSun,
    FaShoppingCart,
  } from "react-icons/fa";
  import { MdTheaterComedy, MdTravelExplore, MdNaturePeople } from "react-icons/md";
  import { GiCookingPot, GiGardeningShears, GiBasketballBasket, GiSoccerBall } from "react-icons/gi";
  import { IoMdFitness, IoMdColorPalette } from "react-icons/io";
  
  const HobbyIcons = {
    Music: <FaMusic />,
    Coding: <FaCode />,
    Cycling: <FaBicycle />,
    Reading: <FaBook />,
    Painting: <FaPaintBrush />,
    Photography: <FaCamera />,
    Gaming: <FaGamepad />,
    Football: <FaFootballBall />,
    Running: <FaRunning />,
    Hiking: <FaHiking />,
    Swimming: <FaSwimmer />,
    Fishing: <FaFish />,
    Gardening: <GiGardeningShears />,
    Nature: <MdNaturePeople />,
    Filmmaking: <FaFilm />,
    Traveling: <MdTravelExplore />,
    Chess: <FaChess />,
    MountainClimbing: <FaMountain />,
    Driving: <FaCar />,
    CoffeeTasting: <FaCoffee />,
    Fitness: <IoMdFitness />,
    WineTasting: <FaWineGlass />,
    Cooking: <GiCookingPot />,
    Basketball: <GiBasketballBasket />,
    Soccer: <GiSoccerBall />,
    Shopping: <FaShoppingCart />,
    Writing: <FaPenNib />,
    Guitar: <FaGuitar />,
    Woodworking: <FaHammer />,
    Meditation: <FaHeart />,
    Sunbathing: <FaSun />,
    Acting: <MdTheaterComedy />,
    Exploring: <MdTravelExplore />,
    DIYProjects: <FaHammer />,
    Yoga: <IoMdFitness />,
    Surfing: <FaSwimmer />,
    BirdWatching: <FaTree />,
    Astrology: <FaSun />,
    Baking: <FaApple />,
    Poetry: <FaPenNib />,
    Volunteering: <FaHeart />,
    Astronomy: <FaSun />,
    BoardGames: <FaChess />,
    Kayaking: <FaFish />,
    RockClimbing: <FaMountain />,
    MartialArts: <FaRunning />,
    Dance: <FaMusic />,
    FashionDesign: <IoMdColorPalette />,
    TattooArt: <IoMdColorPalette />,
    MakeupArtistry: <IoMdColorPalette />,
    CookingExperiments: <GiCookingPot />,
    FitnessTraining: <IoMdFitness />,
  };
  
  export default HobbyIcons;
  